import { useRef } from "react";
import create from "zustand";

const useAuthenticationStore = create((set) => ({
	authenticated: false,
	userMail: "",
	userRole: "",

	logOut: () =>
		set((state) => ({
			authenticated: false,
			userMail: "",
			userRole: "",
		})),
	logIn: async (mail, role) =>
		set((state) => ({
			authenticated: true,
			userMail: mail,
			userRole: role,
		})),
}));

const useProcessStore = create((set) => ({
	currentProcess: "",
	currentStep: 0,
	articles: [{ name: "", code: "", choiceIndex: -1, quan: 0 }],
	parcelCount: 0,
	defaultAddress: {
		company: "",
		name: "",
		surname: "",
		email: "",
		phone: "",
		street: "",
		city: "",
		zip: "",
	},
	address: {
		company: "",
		name: "",
		surname: "",
		email: "",
		phone: "",
		street: "",
		city: "",
		zip: "",
	},
	pickupDate: "0",
	orderResult: "",

	nextStep: () => set((state) => ({ currentStep: state.currentStep + 1 })),
	prevStep: () => set((state) => ({ currentStep: state.currentStep - 1 })),
	setStep: (stepNum) => set((state) => ({ currentStep: stepNum })),

	setArticles: (newArticles) => set((state) => ({ articles: newArticles })),

	setParcelCount: (count) => set((state) => ({ parcelCount: count })),

	setDefaultAddress: (newDefaultAddress) => set((state) => ({ defaultAddress: newDefaultAddress })),

	setAddressField: (field, value) =>
		set((state) => ({
			address: {
				company: field === "company" ? value : state.address.company,
				name: field === "name" ? value.replace(" ", "") : state.address.name,
				surname: field === "surname" ? value.replace(" ", "") : state.address.surname,
				email: field === "email" ? value.replace(" ", "") : state.address.email,
				phone: field === "phone" ? value.replace(" ", "") : state.address.phone,
				street: field === "street" ? value : state.address.street,
				city: field === "city" ? value : state.address.city,
				zip: field === "zip" ? value.replace(" ", "") : state.address.zip,
			},
		})),

	setPickupDate: (date) => set((state) => ({ pickupDate: date })),

	setOrderResult: (result) => set((state) => ({ orderResult: result })),

	clearProcessData: (newProcessName) =>
		set((state) => ({
			currentProcess: newProcessName,
			currentStep: 0,
			articles: [{ name: "", code: "", choiceIndex: -1, quan: 0 }],
			parcelCount: 0,
			address: state.defaultAddress,
			pickupDate: "0",
			orderResult: "",
		})),
}));

const useAnimationStore = create((set) => ({
	loaderState: 'hidden',
	setLoaderState: (cls) => set((state) => ({loaderState: cls})),

	shouldAnimatePickup: false,
	shouldAnimateReturn: false,

	shouldAnimateInvalid: false,

	activatePickupAnimation: () => set((state) => ({ shouldAnimatePickup: true })),
	deactivatePickupAnimation: () => set((state) => ({ shouldAnimatePickup: false })),
	activateReturnAnimation: () => set((state) => ({ shouldAnimateReturn: true })),
	deactivateReturnAnimation: () => set((state) => ({ shouldAnimateReturn: false })),

	activateInvalidAnimation: () => set((state) => ({ shouldAnimateInvalid: true })),
	deactivateInvalidAnimation: () => set((state) => ({ shouldAnimateInvalid: false })),
}));

const useArticlesStore = create((set) => ({
	swapArticles: [],
	sellArticles: [],

	setSwapArticles: (newSwapArticles) => {
		set((state) => ({ swapArticles: newSwapArticles }));
	},
	setSellArticles: (newSellArticles) => {
		set((state) => ({ sellArticles: newSellArticles }));
	},
}));

const usePickupsStore = create((set) => ({
	pickups: [],

	addPickup: (newPickup) => {
		set((state) => ({ pickups: [...state.pickups, newPickup] }));
	},
	setPickups: (newPickups) => {
		set((state) => ({ pickups: newPickups }));
	},
}));

export { useProcessStore };
export { useAnimationStore };
export { useArticlesStore };
export { useAuthenticationStore };
export { usePickupsStore };
