import React, { useEffect, useRef } from "react";
import axios from "axios";

import { ContentCard } from "../../components/content_card/ContentCard.js";

import Arrow from "../../assets/back-arrow.svg";
import Check from "../../assets/check.svg";

import { useProcessStore, useAuthenticationStore, useAnimationStore, usePickupsStore } from "../../stores/generalStore";

export const StepFive = (props) => {
	const currentStep = useProcessStore((state) => state.currentStep);
	const moveNextStep = useProcessStore((state) => state.nextStep);
	const movePrevStep = useProcessStore((state) => state.prevStep);
	const currentProcess = useProcessStore((state) => state.currentProcess);

	const articles = useProcessStore((state) => state.articles);
	const parcelCount = useProcessStore((state) => state.parcelCount);
	const addressData = useProcessStore((state) => state.address);
	const pickupDate = useProcessStore((state) => state.pickupDate);
	const processType = useProcessStore((state) => state.currentProcess);
	const setOrderResult = useProcessStore((state) => state.setOrderResult);

	const userRole = useAuthenticationStore((state) => state.userRole);
	const userMail = useAuthenticationStore((state) => state.userMail);
	const setLoaderState = useAnimationStore((state) => state.setLoaderState);

	const setPickups = usePickupsStore((state) => state.setPickups);

	// scene interaction methods
	const orderPickup = () => {
		setLoaderState("showing");
		axios
			.post("https://ppl.pmmaterialy.cz/create_pickup.php", {
				user_mail: userMail,
				articles: articles,
				parcel_count: parcelCount,
				address_data: addressData,
				pickup_date: pickupDate,
				user_type: userRole,
				process_type: processType,
			})
			.then((response) => {
				axios
					.post("https://ppl.pmmaterialy.cz/get_my_pickups.php", {
						user_mail: userMail,
					})
					.then((response) => {
						console.log(response.data);
						setPickups(response.data);
					});

				console.log(response.data);
				setOrderResult(response.data.importState);
				setLoaderState("hiding");
				moveNextStep();
			});
	};

	const container_ref = useRef();
	useEffect(() => {
		container_ref.current.style.maxHeight = container_ref.current.scrollHeight + "px";
	}, [articles, currentStep]);

	return (
		<ContentCard
			location={"return_step " + (currentStep === 4 ? "active" : "collapsed")}
			width="520"
		>
			<h2>Krok 5: Potvrzení</h2>

			<div
				className="step_container"
				ref={container_ref}
			>
				<div className="summary">
					<div className="basic_info">
						<div>
							<p className="label">Typ zboží</p>
							<p className="data">Poškozené</p>
						</div>
						<div>
							<p className="label">Datum svozu</p>
							<p className="data">{pickupDate}</p>
						</div>
						<div>
							<p className="label">Počet balíčků</p>
							<p className="data">{parcelCount}</p>
						</div>
					</div>
					<div className="address">
						<p className="label">Adresa svozu</p>
						<p className="data">
							{addressData.company === "" ? "" : addressData.company}
							{addressData.company !== "" && <br />}
							{addressData.name} {addressData.surname} <br />
							{addressData.street} <br />
							{addressData.zip} {addressData.city} <br />
							<br />
							{addressData.phone} <br /> {addressData.email} <br />
						</p>
					</div>
					<div className="articles_list">
						{articles.map((val, index) => {
							return (
								<div
									key={index}
									className="article"
								>
									<p>{val.name}</p>
									<p>{ (currentProcess === 'SO_based') ? "" : val.quan }</p>
								</div>
							);
						})}
					</div>

					<div className="control_buttons">
						<button
							className="prev_step step_move"
							onClick={() => movePrevStep()}
						>
							<img
								src={Arrow}
								alt="prev step"
							/>
							<p>Předchozí krok</p>
						</button>
						<div
							//to="/success_upload"
							className="last_step step_move"
							onClick={orderPickup}
						>
							<p>Objednat</p>
							<img
								src={Check}
								alt="finalize order"
							/>
						</div>
					</div>
				</div>
			</div>
		</ContentCard>
	);
};
