import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import PlusIcon from "../../assets/plus.svg";
import AcceptIcon from "../../assets/accept.svg";
import RejectIcon from "../../assets/cross.svg";

import { ContentCard } from "../../components/content_card/ContentCard.js";

import { usePickupsStore, useAuthenticationStore } from "../../stores/generalStore.js";

const PickupDetailScene = (props) => {
	const pickups = usePickupsStore((state) => state.pickups);
	const pickup = pickups[props.pickupID];

    const userRole = useAuthenticationStore((state) => state.userRole);

	let quanStr = "";
	if (pickup.parcelCount === 0) quanStr = "neznámo";
	if (pickup.parcelCount === 1) quanStr = pickup.parcelCount + " balík";
	if (pickup.parcelCount > 1 && pickup.parcelCount < 5) quanStr = pickup.parcelCount + " balíky";
	if (pickup.parcelCount >= 5) quanStr = pickup.parcelCount + " balíků";

	return (
		<ContentCard
			location="my_pickups"
			width="520"
		>
			<h2>
				<Link
					to="/pickups"
					className="backlink"
				/>
				Detail svozu
			</h2>
			<div className="details_container">
				<div className="detail">
					<p>Datum objednání</p>
					<p>{pickup.orderDate}</p>
				</div>
				<div className="detail">
					<p>Datum svozu</p>
					<p>{pickup.pickupDate}</p>
				</div>
				<div className="detail">
					<p>Typ zboží</p>
					<p>{pickup.shipment.type}</p>
				</div>
				<div className="detail">
					<p>Počet balíků</p>
					<p>{quanStr}</p>
				</div>
			</div>
			<div className="articles_container">
				{pickup.items.map((val, index) => {
					return (
						<div
							className="article"
							key={index}
						>
							<p>{val.name}</p>
							<p>{val.code === "SpecialOutput" ? "" : val.quan}</p>
						</div>
					);
				})}
				{(userRole === "DRX") && <AddNoteLine pickupID={props.pickupID} />}
			</div>
		</ContentCard>
	);
};

const AddNoteLine = (props) => {
	const pickups = usePickupsStore((state) => state.pickups);
	const setPickups = usePickupsStore((state) => state.setPickups);

	const [newNote, setNewNote] = useState("");
	const [classes, setClasses] = useState("");

	const handleReject = () => {
		setClasses("");
		setNewNote("");
	};
	const handleAccept = () => {
		if (newNote === "") return false;
		// add to the current list of items
		let newPickups = [...pickups];
		newPickups[props.pickupID].items.push({
			code: "SpecialOutput",
			name: newNote,
			quan: 0,
		});
		setPickups(newPickups);
		// request addition to the databse
		axios.post("https://dpd.pmmaterialy.cz/add_shipment_item.php", {
			item_data: {
				Shipment_ID: pickups[props.pickupID].shipment.ID,
				quan: 0,
				name: newNote,
				code: "SpecialOutput",
			},
		});
		setClasses("");
		setNewNote("");
	};
	const handleNoteChange = (event) => {
		setNewNote(event.target.value);
	};
	const handleAdd = () => {
		setClasses("active");
	};

	return (
		<div className={"addNote " + classes}>
			<input
				className="act"
				type="text"
				placeholder="Vyplňte popis / poznámku / Special output ID"
				onChange={(e) => {
					handleNoteChange(e);
				}}
				value={newNote}
			/>
			<button
				className="act"
				onClick={handleReject}
			>
                <img
                    src={RejectIcon}
                    alt="profile"
                />
            </button>
			<button
				className="act"
				onClick={handleAccept}
			>
                <img
                    src={AcceptIcon}
                    alt="profile"
                />
			</button>
			<button
				className="init"
				onClick={handleAdd}
			>
                <img
                    src={PlusIcon}
                    alt="profile"
                />
            </button>
		</div>
	);
};

export default PickupDetailScene;
