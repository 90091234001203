import React, { useEffect, useMemo, useRef, useState } from "react";

import { ContentCard } from "../../components/content_card/ContentCard.js";

import Arrow from "../../assets/back-arrow.svg";

import { useProcessStore, useAnimationStore } from "../../stores/generalStore";

export const StepThree = (props) => {
	// local states
	const [inputInvalid, setInputInvalid] = useState(false);
	const [formFieldsRefs, setFormFieldsRefs] = useState([
		{ field: "company", ref: useRef(), isInvalid: false },
		{ field: "name", ref: useRef(), isInvalid: false },
		{ field: "surname", ref: useRef(), isInvalid: false },
		{ field: "email", ref: useRef(), isInvalid: false },
		{ field: "phone", ref: useRef(), isInvalid: false },
		{ field: "street", ref: useRef(), isInvalid: false },
		{ field: "city", ref: useRef(), isInvalid: false },
		{ field: "zip", ref: useRef(), isInvalid: false },
	]);

	// zustand stores slicing
	const currentStep = useProcessStore((state) => state.currentStep);
	const moveNextStep = useProcessStore((state) => state.nextStep);
	const movePrevStep = useProcessStore((state) => state.prevStep);

	const addressData = useProcessStore((state) => state.address);
	const setAddressField = useProcessStore((state) => state.setAddressField);

	// scene interaction methods
	const shouldAnimateInvalid = useAnimationStore((state) => state.shouldAnimateInvalid);
	const deactivateInvalidAnimation = useAnimationStore((state) => state.deactivateInvalidAnimation);
	const activateInvalidAnimation = useAnimationStore((state) => state.activateInvalidAnimation);

	const validateAddress = () => {
		let newFormFieldsRefs = [...formFieldsRefs];
		setInputInvalid(false);

		newFormFieldsRefs.forEach((input) => {
			if (input.field === "company") return false;

			if (input.ref.current.value === "") {
				input.isInvalid = true;
				setInputInvalid(true);
			} else {
				input.isInvalid = false;

				if (input.field === "email") {

					const emailRegExp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
					// check for "valid" email address
					if (!emailRegExp.test(input.ref.current.value)) {
						input.isInvalid = true;
						setInputInvalid(true);
					} else {
						input.isInvalid = false;
					}

				} else if (input.field === "phone") {

					const phoneRegExp = /^(\+[0-9]{3})?[ ]?([0-9]{3})[ ]?([0-9]{3})[ ]?([0-9]{3})$/;
					// check for valid phone number
					if (!phoneRegExp.test(input.ref.current.value)) {
						input.isInvalid = true;
						setInputInvalid(true);
					} else {
						input.isInvalid = false;
					}

				} else if (input.field === "zip") {

					const zipRegExp = /^([0-9]{5})$/;
					// check for valid zip
					if (!zipRegExp.test(input.ref.current.value)) {
						input.isInvalid = true;
						setInputInvalid(true);
					} else {
						input.isInvalid = false;
					}

				}
			}
		});

		setFormFieldsRefs(newFormFieldsRefs);
	};

	const handleAddressChange = (event, field) => {
		setAddressField(field, event.target.value);
	};

	const container_ref = useRef();
	useEffect(() => {
		container_ref.current.style.maxHeight = container_ref.current.scrollHeight + "px";
	}, [currentStep]);

    useEffect(() => {
        validateAddress();
    }, [addressData])

	const validateAndMove = () => {
		if (inputInvalid) {
			activateInvalidAnimation();
		} else {
			deactivateInvalidAnimation();
			moveNextStep();
		}
	};

	const invalidStyle = { backgroundColor: "#ffa6a6" };

	return (
		<ContentCard
			location={"return_step " + (currentStep === 2 ? "active" : "collapsed")}
			width="520"
		>
			<h2>Krok 3: Adresa vyzvednutí</h2>

			<div
				className="step_container"
				ref={container_ref}
			>
				<div className="address_form">
					<div className="field">
						<label htmlFor="input_company">Prodejna (volitelné)</label>
						<input
							style={formFieldsRefs[0].isInvalid && shouldAnimateInvalid ? invalidStyle : {}}
							value={addressData.company}
							onChange={(e) => handleAddressChange(e, "company")}
							type="text"
							name="company"
							id="input_company"
							ref={formFieldsRefs[0].ref}
						/>
					</div>
					<div className="dual_line">
						<div className="field">
							<label htmlFor="input_name">Jméno:</label>
							<input
								style={formFieldsRefs[1].isInvalid && shouldAnimateInvalid ? invalidStyle : {}}
								value={addressData.name}
								onChange={(e) => handleAddressChange(e, "name")}
								type="text"
								name="name"
								id="input_name"
								ref={formFieldsRefs[1].ref}
							/>
						</div>
						<div className="field">
							<label htmlFor="input_surname">Příjmení:</label>
							<input
								style={formFieldsRefs[2].isInvalid && shouldAnimateInvalid ? invalidStyle : {}}
								value={addressData.surname}
								onChange={(e) => handleAddressChange(e, "surname")}
								type="text"
								name="surname"
								id="input_surname"
								ref={formFieldsRefs[2].ref}
							/>
						</div>
					</div>
					<div className="field">
						<label htmlFor="input_email">Emailová adresa:</label>
						<input
							style={formFieldsRefs[3].isInvalid && shouldAnimateInvalid ? invalidStyle : {}}
							value={addressData.email}
							onChange={(e) => handleAddressChange(e, "email")}
							type="text"
							name="email"
							id="input_email"
							ref={formFieldsRefs[3].ref}
						/>
					</div>
					<div className="field">
						<label htmlFor="input_phone">Telefonní číslo:</label>
						<input
							style={formFieldsRefs[4].isInvalid && shouldAnimateInvalid ? invalidStyle : {}}
							value={addressData.phone}
							onChange={(e) => handleAddressChange(e, "phone")}
							type="text"
							name="phone"
							id="input_phone"
							ref={formFieldsRefs[4].ref}
						/>
					</div>
					<div className="field">
						<label htmlFor="input_street">Ulice a č.p.:</label>
						<input
							style={formFieldsRefs[5].isInvalid && shouldAnimateInvalid ? invalidStyle : {}}
							value={addressData.street}
							onChange={(e) => handleAddressChange(e, "street")}
							type="text"
							name="street"
							id="input_street"
							ref={formFieldsRefs[5].ref}
						/>
					</div>
					<div className="dual_line">
						<div className="field">
							<label htmlFor="input_city">Město:</label>
							<input
								style={formFieldsRefs[6].isInvalid && shouldAnimateInvalid ? invalidStyle : {}}
								value={addressData.city}
								onChange={(e) => handleAddressChange(e, "city")}
								type="text"
								name="city"
								id="input_city"
								ref={formFieldsRefs[6].ref}
							/>
						</div>
						<div className="field">
							<label htmlFor="input_zip">PSČ:</label>
							<input
								style={formFieldsRefs[7].isInvalid && shouldAnimateInvalid ? invalidStyle : {}}
								value={addressData.zip}
								onChange={(e) => handleAddressChange(e, "zip")}
								type="text"
								name="zip"
								id="input_zip"
								ref={formFieldsRefs[7].ref}
							/>
						</div>
					</div>
				</div>
				<div className="control_buttons">
					<button
						className="prev_step step_move"
						onClick={() => movePrevStep()}
					>
						<img
							src={Arrow}
							alt="prev step"
						/>
						<p>Předchozí krok</p>
					</button>
					<button
						className="next_step step_move"
						onClick={() => validateAndMove()}
					>
						<p>Další krok</p>
						<img
							src={Arrow}
							alt="next step"
						/>
					</button>
				</div>
			</div>
		</ContentCard>
	);
};
