import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Player } from '@lottiefiles/react-lottie-player';
import FailedAnimation from '../../assets/94303-failed.json';

import { useAnimationStore } from '../../stores/generalStore';

const SuccessUploadScene = () => {

	const activateReturnAnimation = useAnimationStore((state) => state.activateReturnAnimation);

    const playerRef = useRef();
    const navigate = useNavigate();

    const handleClick = ( e ) => {
        activateReturnAnimation();
        navigate( "/" );
    }
        


    return(
        
        <div className="animation_container failed">
            <Player
                ref={ playerRef }
                src={ FailedAnimation }
                autoplay
                speed={ 1 }
                keepLastFrame={ true }
            />
            <p className="primary">Objednávka svozu selhala</p>
            <p className="secondary">Administrátor byl o této události informován</p>
            <button onClick={() => handleClick()}>Zkusím to později</button>
        </div>

    )

}


export default SuccessUploadScene;

