import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { ContentCard } from "../../components/content_card/ContentCard.js";

import PlusIcon from "../../assets/plus.svg";
import Arrow from "../../assets/back-arrow.svg";

import { useProcessStore, useArticlesStore, useAnimationStore } from "../../stores/generalStore";

export const StepOne = (props) => {
	// zustand stores slicing
	const currentStep = useProcessStore((state) => state.currentStep);
	const moveNextStep = useProcessStore((state) => state.nextStep);

	const articles = useProcessStore((state) => state.articles);
	const setArticles = useProcessStore((state) => state.setArticles);

	const deactivateInvalidAnimation = useAnimationStore((state) => state.deactivateInvalidAnimation);
	const activateInvalidAnimation = useAnimationStore((state) => state.activateInvalidAnimation);

	// scene interaction methods
	const handleArticleAdd = () => {
		setArticles([...articles, { name: "", code: "", choiceIndex: -1, quan: 0 }]);
	};
	const validateAndMove = () => {
		let inputInvalid = false;

		articles.forEach((artc) => {
			if (artc.name === "") inputInvalid = true;
		});

		if (inputInvalid) {
			activateInvalidAnimation();
		} else {
			deactivateInvalidAnimation();
			moveNextStep();
		}
	};

	const container_ref = useRef();
	useEffect(() => {
		container_ref.current.style.maxHeight = container_ref.current.scrollHeight + "px";
	}, [articles, currentStep]);

	return (
		<ContentCard
			location={"return_step " + (currentStep === 0 ? "active" : "collapsed")}
			width="520"
		>
			<h2>Krok 1: Popis zboží k vrácení</h2>

			<div
				className="step_container"
				ref={container_ref}
			>
				<div className="articles_list">
					{articles.map((val, key) => {
						return (
							<ArticleLine
								key={key}
								index={key}
								name={val.name}
								code={val.code}
								quan={val.quan}
								choiceIndex={val.choiceIndex}
								cont_ref={container_ref}
								line_ref={val.lineRef}
							/>
						);
					})}

					<div className="article add">
						<button onClick={handleArticleAdd}>
							<img
								src={PlusIcon}
								alt="add"
							/>
							<p>Přidat popis</p>
						</button>
					</div>
				</div>

				<div className="control_buttons">
					<Link
						to="/"
						className="prev_step step_move"
					>
						<img
							src={Arrow}
							alt="prev step"
						/>
						<p>Zpět na výběr</p>
					</Link>
					<button
						className="next_step step_move"
						onClick={() => validateAndMove()}
					>
						<p>Další krok</p>
						<img
							src={Arrow}
							alt="next step"
						/>
					</button>
				</div>
			</div>
		</ContentCard>
	);
};

const ArticleLine = (props) => {
	// ZUSTAND store slicing
	const articles = useProcessStore((state) => state.articles);
	const setArticles = useProcessStore((state) => state.setArticles);

	const shouldAnimateInvalid = useAnimationStore((state) => state.shouldAnimateInvalid);

	let lineStyle = useRef();
	if (props.name === "") {
		lineStyle.current = {
			backgroundColor: "#f2f2f2",
		};
		if (shouldAnimateInvalid) {
			lineStyle.current = {
				backgroundColor: "#ffa6a6",
			};
		}
	} else {
		lineStyle.current = {
			backgroundColor: "transparent",
		};
	}

	const handleChange = (event) => {
		let newArticles = [...articles];
		newArticles[props.index].name = event.target.value;
		newArticles[props.index].code = 'SpecialOutput';
		setArticles(newArticles);
	};

	const handleRemove = () => {
		// don't remove if this is the last element
		if (articles.length === 1) return false;

		let newArticles = [...articles];
		newArticles.splice(props.index, 1);
		setArticles(newArticles);
	};

	useEffect(() => {
		if (shouldAnimateInvalid) {
			lineStyle.current = {
				backgroundColor: "#ff0000",
			};
		}
	}, [shouldAnimateInvalid]);

	return (
		<div
			className="article"
			style={lineStyle.current}
		>
			<input
				className="special_output_input"
				type="text"
				placeholder="Vyplňte popis / poznámku / Special output ID"
				onChange={(e) => handleChange(e)}
				value={props.name}
			/>
			<button onClick={() => handleRemove()}></button>
		</div>
	);
};
