import React, { useEffect } from "react";
import moment from "moment-business-days";

import { ContentCard } from "../../components/content_card/ContentCard.js";

import { StepOne } from "./StepOne.js";
import { StepTwo } from "./StepTwo.js";
import { StepThree } from "./StepThree.js";
import { StepFour } from "./StepFour.js";
import { StepFive } from "./StepFive.js";
import SuccessUploadScene from "../../scenes/upload_result/SuccessUploadScene.js";
import FailUploadScene from "../upload_result/FailUploadScene.js";

import { useProcessStore, useAuthenticationStore } from "../../stores/generalStore";
import { useNavigate } from "react-router";

moment.updateLocale("cz", {
	holidays: [
		"01.01.2024",
		"29.03.2024",
		"15.04.2024",
		"18.04.2024",
		"01.05.2024",
		"08.05.2024",
		"05.06.2024",
		"06.06.2024",
		"21.08.2024",
		"28.09.2024",
		"17.11.2024",
		"24.12.2024",
		"25.12.2024",
		"26.12.2024",
		"31.12.2024",
		
		"01.01.2025",
		"18.04.2025",
		"21.04.2025",
		"01.05.2025",
		"08.05.2025",
		"05.06.2025",
		"06.06.2025",
		"28.09.2025",
		"28.10.2025",
		"17.11.2025",
		"24.12.2025",
		"25.12.2025",
		"26.12.2025",
		"31.12.2025",
		
		"01.01.2026",
		"03.04.2026",
		"06.04.2026",
		"01.05.2026",
		"08.05.2026",
		"05.06.2026",
		"06.06.2026",
		"28.09.2026",
		"28.10.2026",
		"17.11.2026",
		"24.12.2026",
		"25.12.2026",
		"26.12.2026",
		"31.12.2026",
	],
	holidayFormat: "DD.MM.YYYY",
	workingWeekdays: [1, 2, 3, 4, 5],
});

const ReturnStockDRXScene = () => {
	// zustand stores slicing
	const currentProcess = useProcessStore((state) => state.currentProcess);
	const clearProcessData = useProcessStore((state) => state.clearProcessData);
	const currentStep = useProcessStore((state) => state.currentStep);
	const orderResult = useProcessStore((state) => state.orderResult);
	const userRole = useAuthenticationStore((state) => state.userRole);

	const navigate = useNavigate();

	// scene interaction methods
	useEffect(() => {
		if ( userRole !== "DRX" ) navigate('/');
		if (currentProcess !== "SO_based") clearProcessData("SO_based");
	}, [currentProcess, clearProcessData, userRole]);

	// current screen decision
	return (
		<>

			{
				(currentStep < 5) 
				&& 
				<ContentCard
					location={"return_step collapsed form_heading"}
					width="520"
				>
					<h2>Objednávka svozu {userRole.replace("X", "")}</h2>
				</ContentCard>
			}
			{(currentStep < 5) && <StepOne />}
			{(currentStep < 5) && <StepTwo />}
			{(currentStep < 5) && <StepThree />}
			{(currentStep < 5) && <StepFour />}
			{(currentStep < 5) && <StepFive />}
			{(currentStep === 5 && orderResult === 'Complete') && <SuccessUploadScene />}
			{(currentStep === 5 && orderResult === 'Error') && <FailUploadScene />}
		
		</>
	);
};

export default ReturnStockDRXScene;
