import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ContentCard } from "../../components/content_card/ContentCard.js";

const ForgotPassErrorScene = () => {
    
    const navigate = useNavigate();

    const handleClick = () => {

        navigate('/');

    }

    return(
        
        
		<ContentCard
            location="forgot_pass_message"
            width="520"
        >
            <p>Něco se nepovedlo, zkuste to prosím později nebo kontaktujte správce aplikace na <a href="mailto:pickup@pmmaterialy.cz">pickup@pmmaterialy.cz</a></p>
            <button onClick={handleClick}>Zpět na přihlášení</button>
        </ContentCard>

    )

}


export default ForgotPassErrorScene;

