import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ContentCard } from "../../components/content_card/ContentCard.js";

const ForgotPassErrorScene = () => {
    
    const navigate = useNavigate();

    const handleClick = () => {

        navigate('/');

    }

    return(
        
        
		<ContentCard
            location="forgot_pass_message"
            width="520"
        >
            <p>Pokud je zadaný email přiřazen k nějakému z účtů, dorazí na něj během pár minut zpráva obsahující vaše heslo. Zkontrolujte případně i složku "nevyžádaná&nbsp;pošta" nebo "spam"</p>
            <button onClick={handleClick}>Zpět na přihlášení</button>
        </ContentCard>

    )

}


export default ForgotPassErrorScene;

