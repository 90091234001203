import React from "react";
import { Link } from "react-router-dom";

import { ContentCard } from "../../components/content_card/ContentCard.js";

import { usePickupsStore } from "../../stores/generalStore.js";

const MyPickupScene = (props) => {
	const pickups = usePickupsStore((state) => state.pickups);

	return (
		<ContentCard
			location="my_pickups"
			width="520"
		>
			<h2>
				<Link
					to="/"
					className="backlink"
				/>
				Moje svozy (posledních 10)
			</h2>
			<div className="pickups_container">
				<div className="pickup">
					<p className="heading">Datum</p>
					<p className="heading">typ</p>
					<p className="heading">Počet balíků</p>
					<Link className="heading" />
				</div>
				{pickups.map((val, index) => {
                    let type = 'Různé';
                    if ( val.shipment.type === 'damaged' ) type = 'Poškozené';
                    if ( val.shipment.type === 'sellable' ) type = 'Prodejní';
					return (
						<PickupRecord
							key={index}
							date={val.pickupDate}
							type={type}
							quan={val.parcelCount}
							id={index}
						/>
					);
				})}
			</div>
		</ContentCard>
	);
};

const PickupRecord = (props) => {
	let quanStr = "";
	if (props.quan === 0) quanStr = "neznámo";
	if (props.quan === 1) quanStr = props.quan + " balík";
	if (props.quan > 1 && props.quan < 5) quanStr = props.quan + " balíky";
	if (props.quan >= 5) quanStr = props.quan + " balíků";

	return (
		<div className="pickup">
			<p>{props.date}</p>
			<p>{props.type}</p>
			<p>{quanStr}</p>
			<Link
				to={`/pickups/${props.id}`}
				attr-id={props.id}
			></Link>
		</div>
	);
};

export default MyPickupScene;
