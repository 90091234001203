import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Player } from '@lottiefiles/react-lottie-player';
import DoneAnimation from '../../assets/23212-order-packed.json';

import { useAnimationStore } from '../../stores/generalStore';


const SuccessUploadScene = () => {


    const playerRef = useRef();
    const navigate = useNavigate();
    
    const activatePickupAnimation = useAnimationStore( state => state.activatePickupAnimation );

    const handlePlayerEvent = ( e ) => {
        if ( e === 'complete' ) {
            activatePickupAnimation();
            navigate( "/" );
        };
    }
        


    return(
        
        <div className="animation_container">
            <Player
                ref={ playerRef }
                src={ DoneAnimation }
                autoplay
                speed={ 1 }
                keepLastFrame={ true }
                onEvent={ ( e ) => handlePlayerEvent( e ) }
            />
        </div>

    )

}


export default SuccessUploadScene;

