import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { ContentCard } from "../../components/content_card/ContentCard.js";

import Arrow from "../../assets/back-arrow.svg";

import { useProcessStore, useAnimationStore } from "../../stores/generalStore";

export const StepTwo = (props) => {
	// zustand stores slicing
	const currentStep = useProcessStore((state) => state.currentStep);
	const moveNextStep = useProcessStore((state) => state.nextStep);
	const movePrevStep = useProcessStore((state) => state.prevStep);

	const parcelCount = useProcessStore((state) => state.parcelCount);
	const setParcelCount = useProcessStore((state) => state.setParcelCount);

	// scene interaction methods
	const handleParcelCountChange = (event) => {
		setParcelCount(event.target.value > 9 ? 9 : event.target.value);
	};

	const shouldAnimateInvalid = useAnimationStore(
		(state) => state.shouldAnimateInvalid
	);
	const deactivateInvalidAnimation = useAnimationStore(
		(state) => state.deactivateInvalidAnimation
	);
	const activateInvalidAnimation = useAnimationStore(
		(state) => state.activateInvalidAnimation
	);

	const container_ref = useRef();
	useEffect(() => {
		container_ref.current.style.maxHeight =
			container_ref.current.scrollHeight + "px";
	}, [currentStep]);

	let inputStyle;
	if (
		parcelCount === 0 ||
		parcelCount === "" ||
		parcelCount === "0"
	) {
		inputStyle = {
			backgroundColor: "#f2f2f2",
		};
		if (shouldAnimateInvalid) {
			inputStyle = {
				backgroundColor: "#ffa6a6",
			};
		}
	}

    
	const validateAndMove = () => {
		let inputInvalid = false;

        if (parcelCount === 0 ||
            parcelCount === "" ||
            parcelCount === "0"
        ) inputInvalid = true;

		if (inputInvalid) {
			activateInvalidAnimation();
		} else {
			deactivateInvalidAnimation();
			moveNextStep();
		}
	};

	return (
		<ContentCard
			location={"return_step " + (currentStep === 1 ? "active" : "collapsed")}
			width="520"
		>
			<h2>Krok 2: Počet balíčků</h2>

			<div className="step_container" ref={container_ref}>
				<div className="parcel_quans">
					<p>
						Kurýrovi předám
						<input
                        style={inputStyle}
							onChange={(e) => handleParcelCountChange(e)}
							type="number"
							min="1"
							max="9"
							placeholder="0"
							value={parcelCount === 0 ? "" : (parcelCount+"").replace(/^0+/, '') }
						/>{" "}
						balíčků.
					</p>
				</div>

				<div className="control_buttons">
					<button
						className="prev_step step_move"
						onClick={() => movePrevStep()}
					>
						<img src={Arrow} alt="prev step" />
						<p>Předchozí krok</p>
					</button>
					<button
						className="next_step step_move"
						onClick={() => validateAndMove()}
					>
						<p>Další krok</p>
						<img src={Arrow} alt="next step" />
					</button>
				</div>
			</div>
		</ContentCard>
	);
};
