export const ContentCard = ( props ) => {
    
    const cardStyle = {
        maxWidth: props.width + "px"
    }

    return(
        <div 
            className={ "content_card " + props.location } 
            style={ cardStyle }
        >
            { props.children }
        </div>
    )

}