import React, { createRef } from "react";
import { Link } from "react-router-dom";

import Return_package from "../../assets/return-package.svg";
import My_pickups from "../../assets/shipping-truck.svg";
import User_settings from "../../assets/id-card.svg";

import { ContentCard } from "../../components/content_card/ContentCard.js";

import { useProcessStore, useAnimationStore, useAuthenticationStore } from "../../stores/generalStore";

const MainSelectionScene = () => {
	const clearProcessData = useProcessStore((state) => state.clearProcessData);
	const setStep = useProcessStore((state) => state.setStep);
	const shouldAnimatePickup = useAnimationStore((state) => state.shouldAnimatePickup);
	const shouldAnimateReturn = useAnimationStore((state) => state.shouldAnimateReturn);
	const deactivatePickupAnimation = useAnimationStore((state) => state.deactivatePickupAnimation);
	const deactivateReturnAnimation = useAnimationStore((state) => state.deactivateReturnAnimation);
	const userRole = useAuthenticationStore((state) => state.userRole);

	const onPickupsAnimationEnd = (e) => {
		if (e.target.classList.contains("pulsate")) e.target.classList.remove("pulsate");
		// clear process data => because animation is triggered with successful pickup upload
		clearProcessData("");
		deactivatePickupAnimation();
	};

	const onReturnAnimationEnd = (e) => {
		if (e.target.classList.contains("pulsate")) e.target.classList.remove("pulsate");
		// on upload fail, set step to 1 and keep the data for later submission
		setStep(0);
		deactivateReturnAnimation();
	};

	return (
		<ContentCard location="main_selection">
			{(userRole === "CSS" || userRole === "CSS_IR" || userRole === "DRX") && (
				<>
					<Link
						to={`/return_stock_${userRole.substring(0,3).toLowerCase()}`}
						className={"main_link " + (shouldAnimateReturn ? "pulsate" : "")}
						id="all"
						onAnimationEnd={(e) => onReturnAnimationEnd(e)}
					>
						<img
							src={Return_package}
							alt="zbozi"
						/>
						<p>Vrátit zboží</p>
					</Link>
					<div className="divider" />
				</>
			)}
			{(userRole === "CSS" || userRole === "CSS_IR" || userRole === "DRX") && ( 
				<>
					<Link
						to="/pickups"
						className={"main_link " + (shouldAnimatePickup ? "pulsate" : "")}
						id="pickups"
						onAnimationEnd={(e) => onPickupsAnimationEnd(e)}
					>
						<img
							src={My_pickups}
							alt="svozy"
						/>
						<p>Moje svozy</p>
					</Link>
				</>
			)}
			{/*(userRole === "CSS" || userRole === "DRX")*/ false && ( 
					<>
						<Link
							to="/account"
							className={"main_link " + (shouldAnimatePickup ? "pulsate" : "")}
							id="pickups"
							onAnimationEnd={(e) => onPickupsAnimationEnd(e)}
						>
							<img
								src={User_settings}
								alt="svozy"
							/>
							<p>Můj účet</p>
						</Link>
					</>
			)}
		</ContentCard>
	);
};

export default MainSelectionScene;
