import React, { createRef, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, useLocation, useOutlet, Link, useNavigate } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Cookies from "universal-cookie";
import axios from "axios";

import { Player } from "@lottiefiles/react-lottie-player";
import LoadingAnimation from "./assets/return-package.json";

import "./index.scss";

import LoginScene from "./scenes/login/Login.js";
import ForgotPassScene from "./scenes/login/Forgot_pass";
import ForgotPassSuccessScene from "./scenes/login/Pass_recovery_success";
import ForgotPassErrorScene from "./scenes/login/Pass_recovery_error";
import MainSelectionScene from "./scenes/main_selection/MainSelectionScene.js";
import MyPickupScene from "./scenes/my_pickups/MyPickupsScene.js";
import MyAccountScene from "./scenes/my_account/MyAccountScene.js";
import PickupDetailScene from "./scenes/my_pickups/PickupDetailScene.js";
import ReturnStockDRXScene from "./scenes/return_stock_drx/ReturnStockDRXScene.js";
import ReturnStockCSSScene from "./scenes/return_stock_css/ReturnStockCSSScene.js";

import UserProfile from "./assets/user-profile.svg";
import HomeIcon from "./assets/home-button.svg";

import { useProcessStore, useAuthenticationStore, useAnimationStore, usePickupsStore } from "./stores/generalStore.js";

const ProtectedRoute = ({ children }) => {
	const navigate = useNavigate();
	const isAuthed = useAuthenticationStore((state) => state.authenticated);
	// if the user is not authed, return to login screen
	useEffect(() => {
		if (!isAuthed) navigate("/login");
	}, [isAuthed]);

	return isAuthed && children;
};

const routes = [
	// unprotected "login" routes
	{path: "/login", 				name: "Login", 				element: <LoginScene />, nodeRef: createRef()},
	{path: "/forgot_pass",			name: "Forgotten password",	element: <ForgotPassScene />,nodeRef: createRef(),},
	{path: "/forgot_pass/success",	name: "Forgotten password",	element: <ForgotPassSuccessScene />,nodeRef: createRef(),},
	{path: "/forgot_pass/error",	name: "Forgotten password",	element: <ForgotPassErrorScene />,nodeRef: createRef(),},
	// protected routes - only users who are logged in
	{path: "*",						name: "Home",				element: <ProtectedRoute><MainSelectionScene /></ProtectedRoute>,nodeRef: createRef(),},
	{path: "/",						name: "Home",				element: <ProtectedRoute><MainSelectionScene /></ProtectedRoute>,nodeRef: createRef(),},
	{path: "/pickups",				name: "My pickups",			element: <ProtectedRoute><MyPickupScene /></ProtectedRoute>,nodeRef: createRef(),},
	{path: "/account",				name: "My account",			element: <ProtectedRoute><MyAccountScene /></ProtectedRoute>,nodeRef: createRef(),},
	{path: "/return_stock_drx",		name: "Return stock DR",	element: <ProtectedRoute><ReturnStockDRXScene /></ProtectedRoute>,nodeRef: createRef(),},
	{path: "/return_stock_css",		name: "Return stock CSS",	element: <ProtectedRoute><ReturnStockCSSScene /></ProtectedRoute>,nodeRef: createRef(),},
	// past pickup screens
	{path: "/pickups/0",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="0" /></ProtectedRoute>),nodeRef: createRef(),},
	{path: "/pickups/1",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="1" /></ProtectedRoute>),nodeRef: createRef(),},
	{path: "/pickups/2",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="2" /></ProtectedRoute>),nodeRef: createRef(),},
	{path: "/pickups/3",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="3" /></ProtectedRoute>),nodeRef: createRef(),},
	{path: "/pickups/4",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="4" /></ProtectedRoute>),nodeRef: createRef(),},
	{path: "/pickups/5",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="5" /></ProtectedRoute>),nodeRef: createRef(),},
	{path: "/pickups/6",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="6" /></ProtectedRoute>),nodeRef: createRef(),},
	{path: "/pickups/7",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="7" /></ProtectedRoute>),nodeRef: createRef(),},
	{path: "/pickups/8",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="8" /></ProtectedRoute>),nodeRef: createRef(),},
	{path: "/pickups/9",name: "My pickups - detail",element: (<ProtectedRoute><PickupDetailScene pickupID="9" /></ProtectedRoute>),nodeRef: createRef(),},
];

const App = () => {
	const location = useLocation();
	const currentOutlet = useOutlet();

	const isAuthed = useAuthenticationStore((state) => state.authenticated);
	const userMail = useAuthenticationStore((state) => state.userMail);
	const userRole = useAuthenticationStore((state) => state.userRole);

	const setPickups = usePickupsStore((state) => state.setPickups);

	const logOut = useAuthenticationStore((state) => state.logOut);
	const clearProcessData = useProcessStore((state) => state.clearProcessData);

	const { nodeRef } = routes.find((route) => route.path === location.pathname);

	useEffect(() => {
		// skip if no userRole given
		if (userRole === "") return;
		// get user's pickup history
		axios.post("https://ppl.pmmaterialy.cz/get_my_pickups.php", {
			user_mail: userMail,
		})
		.then((response) => {
			setPickups(response.data);
		});
	}, [userRole]);
	// init cookies
	const cookies = new Cookies();
	const handleLogOut = () => {
		cookies.remove("login_token");
		clearProcessData("");
		logOut();
	};

	return (
		<div className="app_wrapper">
			<SwitchTransition>
				<CSSTransition
					key={location.pathname}
					nodeRef={nodeRef}
					timeout={125}
					classNames="page"
					unmountOnExit
				>
					{(state) => (
						<div
							ref={nodeRef}
							className={"page"}
						>
							{currentOutlet}
						</div>
					)}
				</CSSTransition>
			</SwitchTransition>

			{isAuthed && (
				<>
					<div className="gradient_bar_top"></div>
					<div className="gradient_bar_bottom"></div>

					<div className="profile_container">
						<div>
							<div className="address_line">
								<img
									src={UserProfile}
									alt="profile"
								/>
								<p>{userMail}</p>
							</div>
							<div
								className="logout"
								onClick={() => handleLogOut()}
							>
								Odhlásit se
							</div>
						</div>
					</div>
					<Link
						className="home_icon"
						to="/"
					>
						<img
							src={HomeIcon}
							alt="home"
						/>
					</Link>
				</>
			)}

			<LoaderOverlay />
		</div>
	);
};
// Loader component
const LoaderOverlay = () => {
	const classState = useAnimationStore((state) => state.loaderState);
	const setClassState = useAnimationStore((state) => state.setLoaderState);
	const handleAnimationEnd = (event) => {
		if (classState === "showing") {
			setClassState("shown");
		} else if (classState === "hiding") {
			setClassState("hidden");
		}
	};

	return (
		<div
			className={"loader " + classState}
			onAnimationEnd={(e) => handleAnimationEnd(e)}
		>
			<Player
				src={LoadingAnimation}
				autoplay
				speed={1}
				keepLastFrame={true}
				loop
			/>
		</div>
	);
};
// browser router
const router = createBrowserRouter([
	{
		path: "*",
		element: <div />,
	},
	{
		path: "/",
		element: <App />,
		children: routes.map((route) => ({
			index: route.path === "/",
			path: route.path === "/" ? undefined : route.path,
			element: route.element,
		})),
	},
]);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<RouterProvider router={router} />);
