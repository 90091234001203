import React, { useEffect, useRef } from "react";
import moment from "moment-business-days";

import { ContentCard } from "../../components/content_card/ContentCard.js";

import Arrow from "../../assets/back-arrow.svg";

import { useProcessStore, useAnimationStore } from "../../stores/generalStore";

export const StepFour = (props) => {
	moment.updateLocale("cz", {
		holidays: [
			// odstavka systemu z duvodu inventury
			"11.09.2023",
			"12.09.2023",
			"13.09.2023",
			"14.09.2023",
			"15.09.2023",
			//
			"28.10.2022",
			"17.11.2022",
			"24.12.2022",
			"25.12.2022",
			"26.12.2022",
			"01.01.2023",
			"07.04.2023",
			"15.04.2023",
			"18.04.2023",
			"01.05.2023",
			"08.05.2023",
			"05.06.2023",
			"06.06.2023",
			"21.08.2023",
			"28.09.2023",
			"28.10.2023",
			"17.11.2023",
			"24.12.2023",
			"25.12.2023",
			"26.12.2023",
			"01.01.2024",
			"29.03.2024",
			"15.04.2024",
			"18.04.2024",
			"01.05.2024",
			"08.05.2024",
			"05.06.2024",
			"06.06.2024",
			"21.08.2024",
			"28.09.2024",
			"28.10.2024",
			"17.11.2024",
			"24.12.2024",
			"25.12.2024",
			"26.12.2024",
		],
		holidayFormat: "DD.MM.YYYY",
		workingWeekdays: [1, 2, 3, 4, 5],
	});
	// zustand stores slicing
	const currentStep = useProcessStore((state) => state.currentStep);
	const moveNextStep = useProcessStore((state) => state.nextStep);
	const movePrevStep = useProcessStore((state) => state.prevStep);

	const pickupDate = useProcessStore((state) => state.pickupDate);
	const setPickupDate = useProcessStore((state) => state.setPickupDate);

	const today = new Date();
	const dateOptions = [
		moment(today).businessAdd(1, "day").format("DD.MM.yyyy"),
		moment(today).businessAdd(2, "days").format("DD.MM.yyyy"),
		moment(today).businessAdd(3, "days").format("DD.MM.yyyy"),
		moment(today).businessAdd(4, "days").format("DD.MM.yyyy"),
		moment(today).businessAdd(5, "days").format("DD.MM.yyyy"),
	];

	// scene interaction methods
	const shouldAnimateInvalid = useAnimationStore((state) => state.shouldAnimateInvalid);
	const deactivateInvalidAnimation = useAnimationStore((state) => state.deactivateInvalidAnimation);
	const activateInvalidAnimation = useAnimationStore((state) => state.activateInvalidAnimation);

	const handleDateChange = (event) => {
		setPickupDate(event.target.value);
	};

	const validateAndMove = () => {
		let inputInvalid = false;

		if (pickupDate === "0") inputInvalid = true;

		if (inputInvalid) {
			activateInvalidAnimation();
		} else {
			deactivateInvalidAnimation();
			moveNextStep();
		}
	};

	let inputStyle;
	if (pickupDate === "0") {
		inputStyle = {
			backgroundColor: "#f2f2f2",
		};
		if (shouldAnimateInvalid) {
			inputStyle = {
				backgroundColor: "#ffa6a6",
			};
		}
	}

	const container_ref = useRef();
	useEffect(() => {
		container_ref.current.style.maxHeight = container_ref.current.scrollHeight + "px";
	}, [currentStep]);

	return (
		<ContentCard
			location={"return_step " + (currentStep === 3 ? "active" : "collapsed")}
			width="520"
		>
			<h2>Krok 4: Datum svozu</h2>

			<div
				className="step_container"
				ref={container_ref}
			>
				<div className="pickup_date">
					<p>
						Chci svoz
						<select
							value={dateOptions.includes(pickupDate) ? pickupDate : "0"}
							onChange={(e) => handleDateChange(e)}
							style={inputStyle}
						>
							<option
								value="0"
								disabled
							>
								Zvolte datum
							</option>
							{dateOptions.map((val, index) => {
								return (
									<option
										key={index}
										value={val}
									>
										{val}
									</option>
								);
							})}
						</select>
					</p>
				</div>

				<div className="control_buttons">
					<button
						className="prev_step step_move"
						onClick={() => movePrevStep()}
					>
						<img
							src={Arrow}
							alt="prev step"
						/>
						<p>Předchozí krok</p>
					</button>
					<button
						className="next_step step_move"
						onClick={() => validateAndMove()}
					>
						<p>Další krok</p>
						<img
							src={Arrow}
							alt="next step"
						/>
					</button>
				</div>
			</div>
		</ContentCard>
	);
};
