import React, { useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Cookies from "universal-cookie";

import { ContentCard } from "../../components/content_card/ContentCard.js";
import { useProcessStore, useAuthenticationStore } from "../../stores/generalStore.js";

const cookies = new Cookies();

const LoginScene = () => {
	const isAuthed = useAuthenticationStore((state) => state.authenticated);
	return (
		!isAuthed && <ContentCard
			location="forgot_pass"
			width="460"
		>
            <h2>
                <Link
                    to="/login"
                    className="backlink"
                />
                Zapomenuté heslo
            </h2>
			<LoginForm />
		</ContentCard>
	);
};

const LoginForm = () => {
	const navigate = useNavigate();
	const isAuthed = useAuthenticationStore((state) => state.authenticated);
	const logIn = useAuthenticationStore((state) => state.logIn);
	const setDefaultAddress = useProcessStore((state) => state.setDefaultAddress);

	const [userMail, setUserMail] = useState("");
	const [userPass, setUserPass] = useState("");

	const handleMailChange = (event) => {
		setUserMail(event.target.value.replace(/\s/g, ""));
	};
	const handlePassChange = (event) => {
		setUserPass(event.target.value);
	};

	const requestPassword = (event) => {
        event.preventDefault();

		axios.post("https://dpd.pmmaterialy.cz/send_password_mail.php", {
            email: userMail
        }).then( ( response ) => {
            if ( response.data === 'Success' ) {
                navigate('/forgot_pass/success');
            } else {
                navigate('/forgot_pass/error');
            }
        });
	};

    useEffect( () => {
        if ( isAuthed ) {
            // if logged in, navigate to main_selection
            navigate('/');
        } else if ( cookies.get('login_token') ) {
            // attempt login via login_token validation
            axios.post("https://dpd.pmmaterialy.cz/validate_login.php", {
                login_token: cookies.get('login_token')
            }).then( ( response ) => {
                //console.log(response.data);
                if ( response.data === 'token invalid') {
                    cookies.remove('login_token');
                } else {
                    //console.log('token_accepted');
                    logIn( response.data.email, response.data.role ).then(() => {
                        setDefaultAddress({
                            company: response.data.default_company,
                            name: response.data.default_name,
                            surname: response.data.default_surname,
                            email: response.data.email,
                            phone: response.data.default_phone,
                            street: response.data.default_street,
                            city: response.data.default_city,
                            zip: response.data.default_zip,
                        });
                        navigate("/");
                    })
                }
            });
        }
    }, [isAuthed]);

	return (
		<form onSubmit={ (e) => requestPassword(e) }>
			<label htmlFor="user_mail">Emailová adresa</label>
			<input
                required
				id="user_mail"
				type="email"
				onChange={(e) => handleMailChange(e)}
				value={userMail}
			/>
			<button>Zažádat o heslo</button>
		</form>
	);
};

export default LoginScene;
